import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { getPostLink } from "../utils/post-meta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { EntryCard } from "../components/entry"
import { CardButtonGroup } from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import { EntryContent, EntryHeader, EntryCardTitle } from "../components/entry"
import { PrimaryButton } from "../components/button"
import PostFooter from "../components/post-footer"

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />

    <StaticQuery
      query={graphql`
        query BlogPosts {
          posts: allMarkdownRemark(
            filter: { fields: { contentKind: { eq: "post" } } }
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                title
                isoDate: date
                textDate: date(formatString: "MMM Do, YYYY")
                tags
              }
              excerpt(format: HTML, pruneLength: 420)
            }
          }
        }
      `}
      render={data =>
        data.posts.nodes.map(post => (
          <EntryCard key={post.id} variant="primary" as="article">
            <EncloseFloats>
              <EntryHeader>
                <EntryCardTitle>
                  <Link to={getPostLink(post)}>{post.frontmatter.title}</Link>
                </EntryCardTitle>
              </EntryHeader>
              <EntryContent
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
              />
            </EncloseFloats>
            <CardButtonGroup>
              <PrimaryButton as={Link} to={getPostLink(post)}>
                Continue reading →
              </PrimaryButton>
            </CardButtonGroup>
            <PostFooter post={post} />
          </EntryCard>
        ))
      }
    />
  </Layout>
)

export default BlogPage
